import _ from "lodash";
import Vue from "vue";

export const state = () => ({
         sidebar: {
           expanded: false,
           hide: true
         },
         createCreativeStarted: false,
         creativeExists: false,
         step: 0,
         // stepId: 0,
         visitedSteps: [],
         completedSteps: [],
         template: false,
         session: false,
         hasSeenComplete: false,
         templatesBaseUrl:
           "https://delivered-by-madington.com/studio/templates/",
         networks: {
           am: {
             name: "Google Ad Manager",
             cachebuster: "%%CACHEBUSTER%%",
             clickTag: "%%CLICK_URL_ESC%%",
             logo: require("../assets/networks/am.png")
           },
           cm: {
             name: "Google Campaign Manager",
             cachebuster: "%n",
             clickTag: "%c_esc",
             logo: require("../assets/networks/cm.png")
           },
           dv360: {
             name: "Google DV360",
             cachebuster: "${CACHEBUSTER}",
             clickTag: "${CLICK_URL_ENC}",
             logo: require("../assets/networks/dv360.png")
           },
           xandr: {
             name: "Xandr",
             cachebuster: "${CACHEBUSTER}",
             clickTag: "${CLICK_URL_ENC}",
             logo: require("../assets/networks/xandr.png")
           },
           adform: {
             name: "Adform",
             cachebuster: "%%ADFRND%%",
             clickTag: "%%c1;cpdir=",
             logo: require("../assets/networks/adform.png")
           },
           adnuntius: {
             name: "Adnuntius",
             cachebuster: "",
             clickTag: "[[adnEscapedClickUrlPlaceholder]]",
             logo: require("../assets/networks/adnuntius.png")
           },
           the_trade_desk: {
             name: "The Trade Desk",
             cachebuster: "",
             clickTag: "%%TTD_CLK_ESC%%",
             logo: require("../assets/networks/the_trade_desk.png")
           },
           delta_projects: {
             name: "Delta projects",
             cachebuster: "",
             clickTag: "{{escapedClick}}",
             logo: require("../assets/networks/delta_projects.png")
           }
         },
         outputData: {}
       });

export const mutations = {
  reset(state) {
    state.step = 0;
    // state.stepId = 0
    Vue.set(state, "visitedSteps", []);
    Vue.set(state, "completedSteps", []);
    Vue.set(state, "template", false);
    Vue.set(state, "session", false);
    Vue.set(state, "outputData", {});
    Vue.set(state.sidebar, "hide", true);
    Vue.set(state, "hasSeenComplete", false);
    Vue.set(state, "creativeExists", true);
  },
  setSession(state, session) {
    Vue.set(state, "session", session);
    Vue.set(state.outputData, "id", session);
    // Vue.set(state, 'stepId', Math.random() + 1)
  },
  setCreateCreativeStarted(state, data) {
    Vue.set(state, 'createCreativeStarted', data);
  },
  updateSize(state, payload){
    payload.width = parseInt(payload.width)
    payload.height = parseInt(payload.height)
    Vue.set(state.template.tagInfo, 'width', payload.width);
    Vue.set(state.template.tagInfo, 'height', payload.height);
    switch (state.template.key) {
      case "netboard-image":
        Vue.set(
          state.template.templateSettings.creativeImage.settings,
          "aspectRatio",
          payload.width / payload.height
        );
        Vue.set(
          state.template.templateSettings.creativeImage.output,
          "dimensions",
          `${payload.width}x${payload.height}`
        );
        break;
      case "netboard-video":
        Vue.set(
          state.template.templateSettings.creativeVideo.output,
          "dimensions",
          `${payload.width}x${payload.height}`
        );
        Vue.set(
          state.template.templateSettings.creativeVideo.settings,
          "aspectRatio",
          payload.width / payload.height
        );
        break;
      case "overlay-netboard-video":
        Vue.set(
          state.template.templateSettings.creativeImage.settings,
          "aspectRatio",
          0
        );
        Vue.set(
          state.template.templateSettings.creativeImage.output,
          "dimensions",
          `${payload.width}x${payload.height}`
        );
        Vue.set(
          state.template.templateSettings.creativeVideo.output,
          "dimensions",
          `${payload.width}x${payload.height}`
        );
        Vue.set(
          state.template.templateSettings.creativeVideo.settings,
          "aspectRatio",
          payload.width / payload.height
        );
        break;
      case "smart-overlay-netboard-video":
        Vue.set(
          state.template.templateSettings.creativeImage.settings,
          "aspectRatio",
          0
        );
        Vue.set(
          state.template.templateSettings.creativeImage.output,
          "dimensions",
          `${payload.width}x${payload.height}`
        );
        Vue.set(
          state.template.templateSettings.creativeVideo.settings,
          "aspectRatio",
          NaN
        );
        Vue.set(
          state.template.templateSettings.creativeVideo.output,
          "dimensions",
          `${payload.width}x${payload.height}`
        );
        break;
      case "smart-netboard-video":
        Vue.set(
          state.template.templateSettings.creativeVideo.settings,
          "aspectRatio",
          NaN
        );
        Vue.set(
          state.template.templateSettings.creativeVideo.output,
          "dimensions",
          `${payload.width}x${payload.height}`
        );
        break;
      case "special":
        Vue.set(
          state.template.templateSettings.creativeVideo.settings,
          "aspectRatio",
          NaN
        );
        Vue.set(
          state.template.templateSettings.creativeVideo.output,
          "dimensions",
          `${payload.width}x${payload.height}`
        );
        break;
    }
  },
  toggleSidebar(state) {
    Vue.set(state.sidebar, "expanded", !state.sidebar.expanded);
  },
  hideSidebar(state, payload) {
    Vue.set(state.sidebar, "hide", payload);
  },
  selectTemplate(state, payload) {
    let template = JSON.parse(JSON.stringify(payload));
    state.step = 0;
    Vue.set(state, "template", template);
    Vue.set(state.outputData, "template", template.templateKey);
    Vue.set(state.outputData, "version", template.version);
  },
  updateSetting(state, payload) {
    Vue.set(
      state.template.templateSettings[payload.key],
      "value",
      payload.value
    );
    Vue.set(state.outputData, payload.key, payload.value);
  },
  updateSplitSetting(state, payload) {
    Vue.set(
      state.template.templateSettings[payload.key].splitValue,
      payload.splitKey,
      payload.value
    );

    if (
      !state.outputData[payload.key] ||
      typeof state.outputData[payload.key] == "string"
    ) {
      Vue.set(state.outputData, payload.key, {
        splitValue: {}
      });
    }

    Vue.set(
      state.outputData[payload.key].splitValue,
      payload.splitKey,
      payload.value
    );
  },
  selectFile(state, payload) {
    Vue.set(state.template.templateSettings[payload.key], "file", payload.file);
  },
  setImage(state, payload) {
    Vue.set(
      state.template.templateSettings[payload.key],
      "image",
      payload.image
    );
  },
  setImageInfo(state, payload) {
    Vue.set(
      state.template.templateSettings[payload.key],
      "info",
      payload.imageInfo
    );
  },
  unsetAsset(state, payload) {
    Vue.set(state.template.templateSettings[payload.key], "image", undefined);
    Vue.set(state.template.templateSettings[payload.key], "file", undefined);
    if (payload.type == "video") {
      Vue.set(state.template.templateSettings[payload.key], "video", undefined);
    }
  },
  setVideoInfo(state, payload) {
    Vue.set(
      state.template.templateSettings[payload.key],
      "info",
      payload.videoInfo
    );
  },
  setVideo(state, payload) {
    Vue.set(
      state.template.templateSettings[payload.key],
      "video",
      payload.video
    );
    Vue.set(
      state.template.templateSettings[payload.key],
      "value",
      payload.video
    );
  },
  setCropData(state, payload) {
    let currentSetting = state.template.templateSettings[payload.key];
    Vue.set(currentSetting.output, "crop", payload.crop);
    if (!state.outputData.files) {
      Vue.set(state.outputData, "files", {});
    }
    if (!state.outputData.files[payload.key]) {
      Vue.set(state.outputData.files, payload.key, {
        path: `delivered-by-madington.com/studio/uploads/temp/${
          state.session
        }/${currentSetting.output.filename}.${currentSetting.file.name
          .split(".")
          .pop()
          .toLowerCase()}`,
        name: `${currentSetting.output.filename}.${currentSetting.file.name
          .split(".")
          .pop()
          .toLowerCase()}`,
        type: payload.type,
        crop: {},
        outputSize: {
          width: parseInt(currentSetting.output.dimensions.split("x")[0]),
          height: parseInt(currentSetting.output.dimensions.split("x")[1])
        }
      });
    }

    if (payload.type == "video" && currentSetting.settings.subtype != "smart") {
      Vue.set(state.outputData.files[payload.key], "crop", {
        x: roundToEven(payload.crop.x),
        y: roundToEven(payload.crop.y),
        width: roundToEven(payload.crop.width),
        height: roundToEven(payload.crop.height),
        scaleX: payload.crop.scaleX,
        scaleY: payload.crop.scaleY
      });
    }

    if (payload.type == "video" && currentSetting.settings.subtype == "smart") {
      // let widthRatio,
      // heightRatio,
      // ratio = 1,
      // currentWidth = currentSetting.info.width,
      // currentHeight = currentSetting.info.height,
      // maxWidth = typeof state.template.tagInfo.width == 'string' ? currentSetting.output.dimensions.split('x')[0] : state.template.tagInfo.width,
      // maxHeight = typeof state.template.tagInfo.height == 'string' ? currentSetting.output.dimensions.split('x')[1] : state.template.tagInfo.height,
      // newWidth,
      // newHeight,
      // sizeRatio = 1;
      //
      // widthRatio = maxWidth / currentWidth;
      // heightRatio = maxHeight / currentHeight;
      // ratio = Math.min(widthRatio, heightRatio);
      //
      // newWidth = currentWidth * ratio
      // newHeight =  currentHeight * ratio
      //
      // if(currentWidth / newWidth >= 2 && currentHeight / newHeight >= 2){
      //   sizeRatio = 2;
      // }

      let scaledAsset = containWithin(
        currentSetting.info.width,
        currentSetting.info.height,
        typeof state.template.tagInfo.width == "string"
          ? currentSetting.output.dimensions.split("x")[0]
          : state.template.tagInfo.width,
        typeof state.template.tagInfo.height == "string"
          ? currentSetting.output.dimensions.split("x")[1]
          : state.template.tagInfo.height
      );

      Vue.set(state.outputData.files[payload.key], "outputSize", {
        width: roundToEven(scaledAsset.width * scaledAsset.scaleRatio),
        height: roundToEven(scaledAsset.height * scaledAsset.scaleRatio)
      });

      Vue.set(state.outputData.files[payload.key], "crop", {
        x: 0,
        y: 0,
        width: roundToEven(currentSetting.info.width),
        height: roundToEven(currentSetting.info.height),
        scaleX: payload.crop.scaleX,
        scaleY: payload.crop.scaleY
      });
    }

    if (payload.type == "image") {
      let output = `https://delivered-by-madington.com/studio/uploads/temp/${
        state.session
      }/${currentSetting.output.filename}.${currentSetting.file.name
        .split(".")
        .pop()
        .toLowerCase()}?crop=${payload.crop.x}:${payload.crop.y}:${
        payload.crop.width
      }:${payload.crop.height}&size=${currentSetting.output.dimensions}`;

      if (currentSetting.settings.subtype == "overlay") {
        let scaledAsset = containWithin(
          currentSetting.info.width,
          currentSetting.info.height,
          typeof state.template.tagInfo.width == "string"
            ? currentSetting.output.dimensions.split("x")[0]
            : state.template.tagInfo.width,
          typeof state.template.tagInfo.height == "string"
            ? currentSetting.output.dimensions.split("x")[1]
            : state.template.tagInfo.height
        );
        if (
          state.template.tagInfo.height <= 600 &&
          state.template.tagInfo.width <= 600
        ) {
          // keep eventual upscale
        } else {
          // Do not upscale
          scaledAsset.scaleRatio = 1;
        }

        Vue.set(state.outputData.files[payload.key], "outputSize", {
          width: roundToEven(scaledAsset.width * scaledAsset.scaleRatio),
          height: roundToEven(scaledAsset.height * scaledAsset.scaleRatio)
        });

        Vue.set(state.outputData.files[payload.key], "crop", {
          x: 0,
          y: 0,
          width: currentSetting.info.width,
          height: currentSetting.info.height,
          scaleX: payload.crop.scaleX,
          scaleY: payload.crop.scaleY
        });
      } else {
        Vue.set(state.outputData.files[payload.key], "crop", {
          x: payload.crop.x,
          y: payload.crop.y,
          width: payload.crop.width,
          height: payload.crop.height,
          scaleX: payload.crop.scaleX,
          scaleY: payload.crop.scaleY
        });
      }

      Vue.set(state.template.templateSettings[payload.key], "value", output);
      Vue.set(
        state.outputData.files[payload.key],
        "maxFileSize",
        state.template.templateSettings[payload.key].maxFileSize
      );
    }

    // Update outputSize to 2x if small netboard and source video allows it
    if (
      state.template.tagInfo.height <= 600 &&
      state.template.tagInfo.width <= 600 &&
      !state.template.tagInfo.fullscreen &&
      currentSetting.settings.subtype != "smart"
    ) {
      if (payload.type == "video") {
        if (
          currentSetting.info.height / state.template.tagInfo.height >= 2 &&
          currentSetting.info.width / state.template.tagInfo.width >= 2
        ) {
          Vue.set(state.outputData.files[payload.key], "outputSize", {
            width: state.template.tagInfo.width * 2,
            height: state.template.tagInfo.height * 2
          });
        }
      } else {
        // Implement @2x for overlays on netboard
      }
    }

    function roundToEven(n) {
      if (n == 0) {
        return n;
      }
      return 2 * Math.round(n / 2);
    }
  },
  toggleSplit(state, payload) {
    Vue.set(
      state.template.templateSettings[payload.key],
      "split",
      !state.template.templateSettings[payload.key].split
    );
  },
  async step(state, stepping) {
    if(parseInt(state.step) + stepping > 0 && parseInt(state.step) + stepping < _.size(state.template.templateSettings)) {
      let currentStepKey = state.template.steps[state.step].contains[0]
      let currentStepSettings = state.template.templateSettings[currentStepKey]

      Vue.set(state, 'visitedSteps', _.union(state.visitedSteps, [parseInt(state.step)]))
      Vue.set(state, 'completedSteps', _.union(state.completedSteps, [parseInt(state.step)]))
      Vue.set(state, 'step', parseInt(state.step) + stepping);
      // Vue.set(state, 'stepId', Math.random() + 1);
    } else {
      // Final step

      if (state.step > 0) {
                            let currentStepKey =
                              state.template.steps[state.step].contains[0];
                            let currentStepSettings =
                              state.template.templateSettings[currentStepKey];

                            if (state.template.type == "portrait-landscape") {
                              Vue.set(
                                state.outputData,
                                "portraitCampaign",
                                `STUDIO_[${
                                  state.outputData.template
                                }-Portrait]_${encodeURIComponent(
                                  state.outputData.advertiser.replace(
                                    /\ /g,
                                    "-"
                                  )
                                )}_${encodeURIComponent(
                                  state.outputData.creativeName.replace(
                                    /\ /g,
                                    "-"
                                  )
                                )}_`.toLowerCase() + state.session
                              );
                              Vue.set(
                                state.outputData,
                                "landscapeCampaign",
                                `STUDIO_[${
                                  state.outputData.template
                                }-Landscape]_${encodeURIComponent(
                                  state.outputData.advertiser.replace(
                                    /\ /g,
                                    "-"
                                  )
                                )}_${encodeURIComponent(
                                  state.outputData.creativeName.replace(
                                    /\ /g,
                                    "-"
                                  )
                                )}_`.toLowerCase() + state.session
                              );
                            } else {
                              Vue.set(
                                state.outputData,
                                "campaign",
                                `STUDIO_[${
                                  state.outputData.template
                                }]_${encodeURIComponent(
                                  state.outputData.advertiser.replace(
                                    /\ /g,
                                    "-"
                                  )
                                )}_${encodeURIComponent(
                                  state.outputData.creativeName.replace(
                                    /\ /g,
                                    "-"
                                  )
                                )}_`.toLowerCase() + state.session
                              );
                            }

                            Vue.set(
                              state.outputData,
                              "po",
                              `STUDIO_[${state.outputData.template}]_${state.session}`
                            );

                            _.each(
                              state.template.templateSettings,
                              (value, key) => {
                                if (
                                  typeof value.splitValue !== "undefined" &&
                                  value.split == false
                                ) {
                                  Vue.set(state.outputData, key, {
                                    splitValue: {
                                      portrait: state.outputData[key],
                                      landscape: state.outputData[key]
                                    }
                                  });
                                }
                              }
                            );

                            if (state.template.type == "portrait-landscape") {
                              Vue.set(state.outputData, "tracking", {
                                portraitTrackingPixel: encodeURIComponent(
                                  state.outputData.trackingPixel.splitValue
                                    .portrait
                                ),
                                landscapeTrackingPixel: encodeURIComponent(
                                  state.outputData.trackingPixel.splitValue
                                    .landscape
                                ),
                                portraitExitUrl:
                                  state.outputData.exitUrl.splitValue.portrait,
                                landscapeExitUrl:
                                  state.outputData.exitUrl.splitValue.landscape
                              });
                            } else {
                              Vue.set(state.outputData, "tracking", {
                                trackingPixel: encodeURIComponent(
                                  state.outputData.trackingPixel
                                ),
                                exitUrl: state.outputData.exitUrl
                              });
                              Vue.set(
                                state.outputData,
                                "width",
                                `${state.template.tagInfo.width}px`
                              );
                              Vue.set(
                                state.outputData,
                                "height",
                                `${state.template.tagInfo.height}px`
                              );
                            }

                            Vue.delete(state.outputData, "trackingPixel");
                            Vue.delete(state.outputData, "exitUrl");

                            Vue.set(state, "step", "complete");
                            Vue.set(
                              state,
                              "visitedSteps",
                              _.union(state.visitedSteps, [state.step])
                            );
                            Vue.set(state, "hasSeenComplete", true);

                            await fetch(`${this.$config.gatewayUrl}/finish/`, {
                              method: "post",
                              body: JSON.stringify(state.outputData)
                            });

        Vue.set(state, "creativeExists", true);

                            // Vue.set(
                            //   state,
                            //   "visitedSteps",
                            //   _.union(state.visitedSteps, [state.step])
                            // );
                            Vue.set(state, "hasSeenComplete", true);
                            Vue.set(state, "step", "complete");
                            // Vue.set(state, 'stepId', Math.random() + 1);
                          }
    }
  },
  updateStepId(state) {
    // Vue.set(state, 'stepId', Math.random() + 1)
  },
  ensureStepInState(state, payload) {
    Vue.set(state, "step", payload);
  },
  goToStep(state, step) {
    /*
      Joakim Sannheim, 2020-03-13
      Don't allow goToStep if current and previous step is not completed.
      All other scenarios should be allowed
    */
    if (
      state.completedSteps.indexOf(step) === -1 &&
      state.completedSteps.indexOf(step - 1) === -1
    )
      return false;
    let currentStepKey = state.template.steps[state.step].contains[0];
    let currentStepSettings = state.template.templateSettings[currentStepKey];

    Vue.set(state, "visitedSteps", _.union(state.visitedSteps, [state.step]));
    Vue.set(state, "step", step);
    // Vue.set(state, 'stepId', Math.random() + 1);
  }
};

function containWithin(cX, cY, mX, mY) {
  let wR,
    hR,
    r = 1,
    nX,
    nY,
    sR = 1;

  wR = mX / cX;
  hR = mY / cY;
  r = Math.min(wR, hR);

  nX = cX * r;
  nY = cY * r;

  if (cX / nX >= 2 && cY / nY >= 2) {
    sR = 2;
  }

  return {
    width: nX,
    height: nY,
    scaleRatio: sR
  };
}
