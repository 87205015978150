import _ from 'lodash'
import Vue from 'vue'

export const state = () => ({
  showCollapsableInfo: false,
  prismicAppData: false,
  prismicTemplatesData: false
})
export const mutations = {
  toggleCollapsableInfo(state){
    Vue.set(state, 'showCollapsableInfo', !state.showCollapsableInfo);
  },
  setPrismicAppData(state, prismicAppData) {
    state.prismicAppData = prismicAppData
  },
  setPrismicTemplatesData(state, prismicTemplatesData) {
    state.prismicTemplatesData = prismicTemplatesData
  }
}
export const actions = {
  async loadPrismicData({ commit }, $prismic) {
    const prismicAppData = await this.$prismic.api.getSingle('studio_app_data')
    const prismicTemplatesData = await this.$prismic.api.query(this.$prismic.predicates.at("document.type", "templates"))
    let sortedTemplates = prismicTemplatesData.results.sort((a, b) => { return a.data['order-in-list'] - b.data['order-in-list'] });
    let templateGroups = {};

    if (sortedTemplates) {

      function typeToGroup(type) {
        switch (type) {
          case 'fluid':
            return 'Fluid'
            break;
          case 'fixed':
            return 'Fixed-size display'
            break;
        }
      }

      _.each(sortedTemplates, (template, index) => {
        let group = typeToGroup(template.data['template_category']);
        if (typeof templateGroups[template.data['template_category']] == 'undefined') {
          templateGroups[template.data['template_category']] = {
            title: group,
            templates: {}
          };
        }
        templateGroups[template.data['template_category']].templates[template['uid']] = template
        templateGroups[template.data['template_category']].templates[template['uid']].title = template.data.title[0].text
      })
    }

    commit('setPrismicAppData', prismicAppData.data)
    commit('setPrismicTemplatesData', templateGroups)
  },
  async nuxtServerInit({ commit }, { $config: { templatesBaseUrl }, $prismic }) { }
}
